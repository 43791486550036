<template>
  <b-container class="areas-list" fluid>
    <div class="w-100 text-center mb-3 d-none">
      <router-link :to="'/areas-map'">
        <b-button class="mt-5" size="lg">Show Forests Map</b-button>
      </router-link>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <b-btn v-if="this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Administrator'"
               class="mr-2" @click="showAllAreas">All forests
        </b-btn>
        <b-btn v-if="this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Administrator'"
               @click="showMyAreas">My forests
        </b-btn>
      </div>
      <div class="col-md-6 col-lg-3 offset-lg-6">
        <b-form-input
          v-model="searchTable"
          placeholder="Search"
          required

          type="text"
          @change="onKeyUp()"
        ></b-form-input>
      </div>
    </div>
    <div v-if="!areasTable" class="d-flex justify-content-center mt-5">
      <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <table id="areas-datatables" class="table table-striped table-bordered mb-3" style="width:100%;">
    </table>
  </b-container>
</template>

<script>
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-fixedheader-bs4/js/fixedHeader.bootstrap4";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4";
import "datatables.net-scroller-bs4/js/scroller.bootstrap4";

import Area from "../../api/Area"
import {mapActions, mapGetters} from "vuex";
// import moment from 'moment';
import Config from "../../api/Config";
// import Stats from '../../api/Stats'
import $ from 'jquery'
import Swal from "sweetalert2";

export default {
  name: 'AreasList',

  data() {
    return {
      areas: null,
      userId: null,
      config: null,
      stats: {},
      areasTable: null,
      searchTable: null,
    }
  },
  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount"]),
  },
  async mounted() {
    this.config = (await Config.getConfig()).data;
    // Stats.getStats()
    //     .then((stats) => {
    let global = this;
    $(document).on('click', '.claim-btn', function () {
      const id = $(this).data('id')
      if (id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "By claiming, the forest wallet will get the farmed ZCO2 until now",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Processing',
              html: 'Waiting for confirmations',
              didOpen: () => {
                Swal.showLoading()
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
            Area.claim(id).then((data) => {
              global.loadTableClaimableStats(id)
              Swal.fire(
                'Claimed successfully created',
                `You've successfully claimed your ZCO2 tokens.<br><a href="${global.config.tx_url_template.replace('{tx}', data.data.hash)}" target="_blank">Go to explorer</a>`,
                'success');

            }).catch((e) => {
              Swal.fire(
                'Error',
                e.message,
                'error');
            })
          }
        })

      }
    })
    if (this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Authenticated') {
      this.userId = this.getWeb2ActiveAccount.id;
    }


    this.areasTable = $('#areas-datatables').DataTable({
      processing: true,
      serverSide: true,
      language: {
           processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>Processing...'
      },
      // stateSave: true,
      dom: 't<"row mt-2"<"d-none d-md-block col-md-6"l><"col-md-6"p>>',
      lengthMenu: [[14, 25, 50, 100, 200, 10000000000000], [14, 25, 50, 100, 200, "All"]],
      order: [1, 'desc'],
      ajax: {
        url: process.env.VUE_APP_API_URL + '/areas-datatables',
        type: 'POST',
        data: (d) => {
          d.userId = this.userId;
        }
      },
      columns: [
        {
          width: 48,
          title: '',
          searchable: false,
          orderable: false,
          data: 'image',
          className: 'p-0',
          defaultContent: '',
        },
        {
          width: 40,
          title: 'ID',
          data: 'id',
          defaultContent: '',
        },
        {
          title: 'Name',
          data: 'name',
          defaultContent: ''
        },
        {
          title: 'Forest Owner',
          data: 'owner',
          defaultContent: ''
        },
        {
          title: 'Country',
          data: 'country',
          defaultContent: ''
        },

        {
          title: 'Trees',
          data: 'trees',
          className: 'text-right',
          defaultContent: ''
        },

        {
          title: 'CCO2 Ton farmed per year',
          data: 'theoreticalIncrement',
          className: 'text-right',
          width: 200,
          orderable: false,
          searchable: false,
          defaultContent: ''
        },
        {
          title: 'CCO2 Ton available',
          data: 'balance',
          className: 'text-right',
          width: 200,
          orderable: false,
          searchable: false,
          defaultContent: ''
        },
        {
          title: 'Claim',
          data: 'theoreticalIncrement',
          className: 'claim-box',
          orderable: false,
          searchable: false,
          width: 150,
          defaultContent: ''
        }
      ],
      columnDefs: [
        {
          targets: 0,
          responsivePriority: 1,
          data: 'image',
          // visible: false,
          render: (image, type, all) => {
            // return image;

            if (type === 'display') {
              return image ? `
                <a href="/areas/${all.id}">
                    <img id="area-img-${all.id}" alt="${all.name}" src="${process.env.VUE_APP_API_URL + image}">
                </a>
              ` : '';
            } else {
              return image ? `${process.env.VUE_APP_API_URL + image}` : '';
            }
          },
        },
        {
          targets: 1,
          responsivePriority: 0,
          data: 'id',
          render: (id, type) => {
            // return image;
            if (type === 'display') {
              return id ? `
              <a href="/areas/${id}" class="text-reset">
                ${id}
              </a>
              ` : '';
            } else {
              return id;
            }
          },
        },
        {
          targets: 2,
          data: 'name',
          render: (name, type, all) => {
            // return image;
            if (type === 'display') {
              return all.id ? `
              <a href="/areas/${all.id}" class="text-reset">
                ${name}
              </a>
              ` : '';
            } else {
              return name;
            }
          },
        },
        {
          targets: 5,
          data: 'trees',
          // visible: false,
          render: (trees, type) => {

            // const currentYearTheoreticalIncrement = trees ? parseInt(trees) : 0;

            if (type === 'display') {
              return `

${new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0
              }).format(trees)}
     `;

            } else {

              return `${new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0
              }).format(trees)}`;

            }
          },
        },
        {
          targets: 6,
          responsivePriority: 8,
          data: 'theoreticalIncrement',
          // visible: false,
          render: (theoreticalIncrement, type) => {

            const currentYearTheoreticalIncrement = theoreticalIncrement ? parseFloat(theoreticalIncrement) : 0;

            if (type === 'display') {
              return `
        <div class="text-right">
${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(currentYearTheoreticalIncrement)} Ton CCO2
        </div>`;

            } else {

              return `${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(currentYearTheoreticalIncrement)} Ton CCO2`;

            }
          },
        },
        {
          targets: 7,
          responsivePriority: 8,
          data: 'balance',
          // visible: false,
          render: (balance, type) => {

            balance = balance ? parseFloat(balance) : 0;

            if (type === 'display') {
              return `
        <div class="text-right">
${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(balance)} Ton CCO2
        </div>`;

            } else {

              return `${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(balance)} Ton CCO2`;

            }
          },
        },
        {
          targets: 8,
          responsivePriority: 2,
          data: 'theoreticalIncrement',
          // visible: false,
          render: (theoreticalIncrement, type, all) => {
            // const currentYearTheoreticalIncrement = theoreticalIncrement ? parseFloat(theoreticalIncrement) : 0;
            // const claimable = currentYearTheoreticalIncrement / (365 * 24 * 60 * 60) * moment().diff(moment([2022, 0]), 'seconds');


            if (type === 'display' && $(`#claimable-${all.id}`).length === 0) {
              if (all.token_id) {
                this.loadTableClaimableStats(all.id)
              }

              return `
              <div style="width: 150px;">
          ${all.token_id ?
                `
            <button id="claimable-${all.id}" data-id="${all.id}" class="btn btn-sm btn-primary btn-block claim-btn" disabled>
            Claim
              <span id="claimable-amount-${all.id}" class="badge badge-light my-1">...</span>
            </button>
          ` :
                `
                <button  class="btn btn-sm btn-secondary btn-block claim-btn" disabled>
            Pending
            </button>
`
              }</div>
              `;

            } else {

              return ``;

            }
          },
        }
      ]
    });




    //     }).catch((error) => {
    //   console.log(error)
    // })
  },
  methods: {
    ...mapActions("tokenContract", ["claim"]),
    onKeyUp() {
      this.areasTable.search(this.searchTable).draw();
    },
    showMyAreas() {
      this.userId = this.getWeb2ActiveAccount.id;
      this.areasTable.ajax.reload();
    },
    showAllAreas() {
      this.userId = null;
      this.areasTable.ajax.reload();
    },
    loadTableClaimableStats(areaId) {
      $('#claimable-' + areaId).prop('disabled', true);
      $('#claimable-amount-' + areaId).html(`...`);

      Area.getClaimable(areaId).then((data) => {
        const claimable = data.data.totalCO2;
        if (parseFloat(claimable)) {
          $('#claimable-' + areaId).prop('disabled', false);
        } else {

          $('#claimable-' + areaId).prop('disabled', true);

        }

        $('#claimable-amount-' + areaId).html(new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(claimable))

      })
    },
    getAreas() {

    }
  }
}
</script>

<style scoped>

</style>
